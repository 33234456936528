@import "~bootstrap/dist/css/bootstrap.css";
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');



.fondo {
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
    background-image: url(assets/nubes.png);
}

.sol { 
    position: absolute;
    width: 12%; 
    z-index: -1; 
    margin-top: -9%;
    margin-left: 87.85%;
}

    #animation {
        animation:heartBeat 2s infinite;
    }

    @keyframes heartBeat {
        0% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
  
        14% {
            -webkit-transform: scale(1.03);
            transform: scale(1.03);
        }
  
        28% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
  
        42% {
            -webkit-transform: scale(1.03);
            transform: scale(1.03);
        }
  
        70% {
            -webkit-transform: scale(1);
            transform: scale(1);
        }
  }
